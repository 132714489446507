<template>
  <div class="ExamItem">
    <div
      class="text-item-top"
      :class="{
        'show-item-bottom':
          examObj.status > 1 ||
          !examObj.paperList ||
          examObj.paperList.length == 0 ||
          !showTable ||
          reportObject.statusStat == 2,
      }"
    >
      <!--顶部第一个盒子-->
      <div class="top-box">
        <div class="title">
          <!--状态盒子-->
          <div v-if="examObj.status == 3" class="status">
            <div class="tips">已统计</div>
          </div>
          <div v-if="examObj.status == 2" class="status status-succ">
            <div class="tips">已结束</div>
          </div>
          <div v-if="examObj.status == 1" class="status status-ing">
            <div class="tips">进行中</div>
          </div>
          <div>{{ examObj.name }}</div>
          <span v-if="examObj.ruleId > 0" class="title-type title-type-new"
            >新高考</span
          >
          <span v-else class="title-type">普通考试</span>
          <div class="tips-item">
            <p>
              {{
                (examObj.level == 3
                  ? "高"
                  : examObj.level == 1
                  ? "小"
                  : examObj.level == 4
                  ? "大"
                  : "初") +
                examObj.year +
                "级"
              }}
            </p>
            <p v-if="examObj.examType == 0">
              {{ findLabelByValue(examTypeOptions, examObj.type) }}
            </p>
            <p :class="{ 'securityLevel-err': examObj.securityLevel == 2 }">
              {{ examObj.securityLevel | setName(examSecurityLevelOptions) }}
            </p>
          </div>
          <!--  -->
          <span v-if="reportObject.statusStat == 2" class="err">
            {{ reportObject.jobMessage }}
          </span>
        </div>
        <div class="top-right-box">
          <div style="color: #101011">考试日期：{{ examObj.examDate }}</div>
          <div
            v-if="
              (!examObj.status || examObj.status == 1) &&
              examObj.paperList &&
              examObj.paperList.length > 0
            "
            class="hidden-table"
            :class="{ 'show-table': showTable }"
            @click="showTable ? (showTable = false) : (showTable = true)"
          >
            <!-- <img src="@/static/exam/showList.png" alt="" /> -->
            <i class="el-icon-arrow-down"></i>
            <span v-if="showTable">收起</span>
            <span v-if="!showTable">展开</span>
          </div>
        </div>
      </div>

      <!--按钮盒子-->
      <!--考生管理│模板设置│试题管理│临时榜│考务权限│成绩管理│微课录制│单科统计-->
      <!--修改│结束项目│删除项目-->
      <div v-if="reportObject.statusStat != 2" class="btn-box">
        <div class="btn-item">
          <p v-if="getRoleShow(1, 1)" @click="examFunctionBtns(1, examObj)">
            <img src="@/assets/exam/examItem/exam_stu.png" alt="" />
            <img
              class="on"
              src="@/assets/exam/examItem/exam_stu_on.png"
              alt=""
            />
            考生管理
          </p>
          <p v-if="userInfo.roleId != 5" @click="examFunctionBtns(9, examObj)">
            <img src="@/assets/uExam/icon_teacher.png" alt="" />
            <img class="on" src="@/assets/uExam/icon_teacher_on.png" alt="" />
            阅卷教师管理
          </p>
          <p v-if="userInfo.roleId != 5" @click="examFunctionBtns(10, examObj)">
            <img src="@/assets/uExam/icon_scan.png" alt="" />
            <img class="on" src="@/assets/uExam/icon_scan_on.png" alt="" />
            扫描点管理
          </p>
          <template v-if="getRoleShow(5, 1)">
            <p @click="examFunctionBtns(4, examObj)">
              <img src="@/assets/exam/examItem/temporary.png" alt="" />
              <img
                class="on"
                src="@/assets/exam/examItem/temporary_on.png"
                alt=""
              />
              临时榜
            </p>
          </template>
          <!-- <template
            v-if="examObj.questionPermission && userInfo.schoolVersion != 1"
          >

            <p @click="examFunctionBtns(3, examObj)">
              <img src="@/assets/exam/examItem/question.png" alt="" />
              <img
                class="on"
                src="@/assets/exam/examItem/question_on.png"
                alt=""
              />
              试题管理
            </p>
          </template> -->

          <template v-if="showBtn() && examObj.status < 2">
            <p
              v-if="examObj.securityLevel == 1"
              @click="examFunctionBtns(5, examObj)"
            >
              <img src="@/assets/exam/examItem/examination.png" alt="" />
              <img
                class="on"
                src="@/assets/exam/examItem/examination_on.png"
                alt=""
              />
              考务分工
            </p>
          </template>
          <p
            v-if="examObj.changeScorePermission"
            @click="examFunctionBtns(6, examObj)"
          >
            <img src="@/assets/exam/examItem/transcript.png" alt="" />
            <img
              class="on"
              src="@/assets/exam/examItem/transcript_on.png"
              alt=""
            />
            成绩管理
          </p>
          <template v-if="showBtn() && examObj.status < 2">
            <p @click="showTask()">
              <img src="@/assets/uExam/icon_task.png" alt="" />
              <img class="on" src="@/assets/uExam/icon_task_on.png" alt="" />
              阅卷任务显示
            </p>
          </template>
        </div>

        <div class="btn-item">
          <template v-if="showBtn()">
            <p v-if="examObj.status == 1" @click="overExam(examObj)">
              <!-- end -->
              <img src="@/assets/exam/examItem/end.png" alt="" />
              <img class="on" src="@/assets/exam/examItem/end_on.png" alt="" />
              结束考试
            </p>
            <p v-if="examObj.status != 1" @click="startExam(examObj)">
              <img src="@/assets/exam/examItem/end.png" alt="" />
              <img class="on" src="@/assets/exam/examItem/end_on.png" alt="" />
              开启考试
            </p>

            <template v-if="getRoleShow(4, 1) && examObj.status > 1">
              <!-- │
            <p @click="showResults(examObj)">发布成绩</p> -->

              <p @click="goParameter(examObj)">
                <img src="@/assets/exam/examItem/statistics.png" alt="" />
                <img
                  class="on"
                  src="@/assets/exam/examItem/statistics_on.png"
                  alt=""
                />
                统计分析
              </p>
            </template>
            <template v-if="examObj.status < 2">
              <p @click="editList(examObj)">
                <img src="@/assets/exam/examItem/edit.png" alt="" />
                <img
                  class="on"
                  src="@/assets/exam/examItem/edit_on.png"
                  alt=""
                />
                修改
              </p>

              <p @click="deleteHandle(examObj.id, examObj.name)">
                <img src="@/assets/exam/examItem/delete.png" alt="" />
                <img
                  class="on"
                  src="@/assets/exam/examItem/delete_on.png"
                  alt=""
                />
                删除项目
              </p>
            </template></template
          >
          <p @click="showExamDetail()">
            <img src="@/assets/exam/examItem/show.png" alt="" />
            <img class="on" src="@/assets/exam/examItem/show_on.png" alt="" />
            查看考试信息
          </p>
        </div>
      </div>
      <div
        v-if="reportObject.statusStat == 2 && examObj.status != 1"
        class="stat-progress"
      >
        <el-progress
          :percentage="reportObject.statProgress"
          :stroke-width="10"
          :show-text="false"
        ></el-progress>
      </div>
    </div>

    <!--表格盒子-->
    <el-table
      v-if="
        examObj.paperList &&
        examObj.paperList.length > 0 &&
        examObj.status == 1 &&
        reportObject.statusStat != 2
      "
      :data="examObj.paperList"
      border
      class="table-style"
      :class="{ 'hidden-list': !showTable }"
    >
      <!-- style="" -->
      <el-table-column
        prop="subjectName"
        label="科目"
        :width="userInfo.roleId == 5 ? '400' : '96'"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope">
            <div>{{ scope.row.subjectName }}</div>
          </template>
        </template>
      </el-table-column>

      <el-table-column
        v-if="getListShow(1)"
        prop="name"
        label="模板设置"
        width="144"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <el-checkbox v-model="scope.row.name"></el-checkbox> -->
          <template v-if="scope">
            <div
              v-if="getTabShow(1, scope.row.subjectId, scope.row)"
              class="table-box"
            >
              <i v-if="scope.row.statusSheet > 0" class="el-icon-success"></i>
              <i v-else class="el-icon-remove"></i>
              <div
                class="hidden-box"
                @click="
                  examTemplateHandle(
                    examObj.id,
                    examObj.name,
                    scope.row.subjectId
                  )
                "
              >
                <i class="el-icon-setting"></i>
                进入设置
              </div>
            </div>
            <template v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="暂无权限"
                placement="top"
              >
                <span>-</span>
              </el-tooltip>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-if="getListShow(2)"
        prop="name"
        label="答案设置"
        width="144"
        align="center"
      >
        <template slot-scope="scope">
          <!-- @click="examPaperHandle(scope.row.id)" -->
          <template v-if="scope">
            <div
              v-if="getTabShow(2, scope.row.subjectId, scope.row)"
              class="table-box"
            >
              <i v-if="scope.row.statusAnswer == 0" class="el-icon-remove"></i>
              <i v-if="scope.row.statusAnswer == 1" class="el-icon-success"></i>

              <img
                v-if="scope.row.statusAnswer == 2"
                class="icon-img"
                src="@/assets/imgs/exam/check.png"
                alt=""
              />
              <div
                v-if="scope.row.statusSheet > 0"
                class="hidden-box"
                @click="examPaperAnswerHandle(examObj.id, scope.row.paperId)"
              >
                <i class="el-icon-setting"></i>
                进入设置
              </div>
            </div>
            <template v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="暂无权限"
                placement="top"
              >
                <span>-</span>
              </el-tooltip>
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-if="examObj.scanPermission"
        prop="name"
        label="扫描管理"
        :width="'144'"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope">
            <div class="table-box">
              <div
                v-if="scope.row.statusSheet > 0"
                class="hidden-box"
                @click="scanrecordCheckRecognition(scope.row, examObj)"
              >
                <i class="el-icon-setting"></i>
                开始扫描
              </div>
              <i v-if="scope.row.statusScan == 1" class="el-icon-success"></i>
              <i v-if="scope.row.statusScan == 0" class="el-icon-remove"></i>
              <img
                v-if="scope.row.statusScan == 2"
                class="icon-img"
                src="@/assets/imgs/exam/check.png"
                alt=""
              />
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-if="getListShow(3)"
        prop="name"
        label="阅卷分配"
        align="center"
        width="144"
      >
        <template slot-scope="scope">
          <!--           <template > -->
          <div
            v-if="getTabShow(3, scope.row.subjectId, scope.row)"
            class="table-box"
          >
            <template>
              <i v-if="scope.row.statusMark > 0" class="el-icon-success"></i>
              <i
                v-if="!scope.row.statusMark && scope.row.sheetMarkType != 1"
                class="el-icon-remove"
              ></i>
              <span v-if="scope.row.sheetMarkType == 1">先阅后扫模板</span>
              <img
                v-if="scope.row.statusMark == -1"
                class="icon-img"
                src="@/assets/imgs/exam/check.png"
                alt=""
              />
              <div
                v-if="scope.row.statusSheet > 0 && scope.row.sheetMarkType != 1"
                class="hidden-box"
                @click="correctTask(scope.row, examObj.name)"
              >
                <i class="el-icon-setting"></i>
                阅卷分配
              </div>
            </template>
          </div>
          <template v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="暂无权限"
              placement="top"
            >
              <span>-</span>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>

      <el-table-column
        prop="address"
        :label="progressType ? '阅卷控制 进度获取中...' : '阅卷控制'"
        align="center"
      >
        <template slot-scope="scope">
          <div
            class="table-box kz-box"
            @click="linkToMonitor(scope.row, examObj)"
          >
            <!--    scope.row.markAbnormal 存在阅卷报警           -->
            <img
              v-if="scope.row.markAbnormal"
              class="icon-img"
              src="@/assets/imgs/exam/check.png"
              alt=""
            />
            <div class="hidden-box">
              <i class="el-icon-setting"></i>
              查看详情
            </div>
            <div class="left">
              <!--横条-->
              <span v-if="scope.row.sheetMarkType == 1" class="span-intro"
                >先阅后扫进度</span
              >
              <!-- <template> -->
              <template
                v-if="
                  scope.row.rate &&
                  getTabShow(8, scope.row.subjectId, scope.row, scope.row)
                "
              >
                <el-progress
                  :percentage="Number((scope.row.rate * 100).toFixed(2))"
                  :class="{ exception: scope.row.markAbnormal }"
                  :text-inside="false"
                  :show-text="false"
                ></el-progress>
              </template>
              <template
                v-if="
                  !scope.row.rate ||
                  !getTabShow(8, scope.row.subjectId, scope.row)
                "
              >
                <el-progress
                  :percentage="0"
                  :text-inside="false"
                  :show-text="false"
                ></el-progress>
              </template>
              <div class="progress-box-text">
                <template v-if="getTabShow(8, scope.row.subjectId, scope.row)">
                  {{ scope.row.rate | setNumber }} %
                </template>
                <template v-else>-</template>
              </div>
              <!-- </template> -->
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="getListShow(6)"
        prop="address"
        label="阅卷开关"
        align="center"
        :width="
          getListShow(6) || examObj.paperList[0].getType == 2 ? '144' : 'auto'
        "
      >
        <template slot-scope="scope">
          <template v-if="getTabShow(6, scope.row.subjectId, scope.row)">
            <el-tooltip
              v-if="scope.row.examSwitchStatus == 0"
              class="item"
              effect="dark"
              content="阅卷已全部关闭"
              placement="top"
              :open-delay="300"
            >
              <img
                class="switch-icon"
                src="@/assets/uExam/icon_close.png"
                alt=""
                @click="showSwitch(scope.row)"
              />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.examSwitchStatus == 1"
              class="item"
              effect="dark"
              content="阅卷小组或小题部分开启"
              placement="top"
              :open-delay="300"
            >
              <img
                class="switch-icon"
                src="@/assets/uExam/icon_close1.png"
                alt=""
                @click="showSwitch(scope.row)"
              />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.examSwitchStatus == 2"
              class="item"
              effect="dark"
              content="阅卷小组或小题全部开启"
              placement="top"
              :open-delay="300"
            >
              <img
                class="switch-icon"
                src="@/assets/uExam/icon_close2.png"
                alt=""
                @click="showSwitch(scope.row)"
              />
            </el-tooltip>
          </template>
          <template v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="暂无权限"
              placement="top"
            >
              <span>-</span>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="导出班级讲评报告" :visible.sync="downModel" width="450px">
      <div class="export-parameter">
        <span>科目：</span>
        <el-select
          v-model="indexSubjectId"
          style="width: 110px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in indexPaperList"
            :key="item.subjectId"
            :label="item.subjectName"
            :value="item.subjectId"
          >
          </el-option>
        </el-select>
      </div>
      <!-- padding-left: 45px;  -->
      <div style="padding-bottom: 24px">
        <span>班级：</span>
        <el-button type="text" @click="changeClassNum(1)">全选</el-button>
        <el-button type="text" @click="changeClassNum(2)">反选</el-button>
        <el-button type="text" @click="changeClassNum(3)">取消</el-button>
      </div>
      <div class="export-parameter">
        <el-checkbox-group v-model="indexClassNum">
          <el-checkbox
            v-for="(v, i) in indexClassNumList"
            :key="i"
            :label="v.classNum"
          ></el-checkbox>
        </el-checkbox-group>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="downModel = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="downloadclassnumteachreport"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="570px">
      <span v-if="!examObj.statId">
        确定要结束考试流程吗？
        <br />
        提示：结束考试流程后，可根据需要设定统计参数，生成统计分析结果
      </span>
      <div v-else>
        确定要结束考试流程吗？
        <br />

        是否重新生成统计：
        <el-radio v-model="isRegenerate" :label="1" style="margin-bottom: 0">
          是
        </el-radio>
        <el-radio v-model="isRegenerate" :label="0" style="margin-bottom: 0">
          否
        </el-radio>
        <br />
        提示：重新生成统计后为了确保数据一致性：
        <br />

        1、系统自动将“已生成”统计考试变为“未生成”状态，需要重新计算统计分析。
        <br />

        2、教师端统计数据及分析报告将无法查看，重新生成统计后可正常查看。
        <br />

        3、学生端成绩不自动取消，如需要可手动取消发布
      </div>
      <div style="color: red">{{ errStr }}</div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" :loading="btnLoading" @click="submitExam"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="温馨提示" :visible.sync="delPassword" width="400px">
      <div>
        此操作将删除
        <span class="err">{{ examObj.name }}</span>
        考试，此操作会删除
        <span class="err">阅卷记录，考生分数，答题卡记录</span>
        等等！属于高危操作，请确定后删除！
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <el-input class="password-text" type="text"></el-input>
        <!-- <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          :readonly="readonly"
          show-password
          @keyup.enter.native="monitorBack"
          @focus="hanldClick"
        ></el-input> -->
        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          @keyup.enter.native="monitorBack"
          @focus="hanldClick"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(delPassword = false) && (multipleSelection = [])"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :loading="modelLoading"
          @click="monitorBack()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="开启阅卷"
      class="exam-type"
      :visible.sync="openClosedialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <div class="tips">
        <p>注意</p>
        <p>1、试评功能用于初次阅卷或预演阅卷，并且试评只能进行一次</p>
        <p>2、开启正评会自动清空试试评阅卷记录，并且无法再次开启试评；</p>
      </div>
      <div>
        开启试评：
        <el-switch v-model="open1" active-color="#2474ED" :disabled="open2">
        </el-switch>

        开启正评：
        <el-switch v-model="open2" active-color="#2474ED" :disabled="open1">
        </el-switch>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="openPaper()">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="开启正评"
      class="exam-type"
      :visible.sync="formalDialogVisible"
      width="600px"
    >
      {{ indexPaper.subjectName }}科目阅卷开关
      <el-switch v-model="open2" active-color="#2474ED" @change="changeOpen()">
      </el-switch>
      <el-table :data="questionList" style="width: 100%" border>
        <el-table-column
          prop="examQuestionNumber"
          label="主观题"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.isOption == 1">选做题</template>
            <template v-else>主观题</template>
            {{ scope.row.examQuestionNumber }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="小题阅卷开关"
          width="179"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.typeT"
              active-color="#2474ED"
              :disabled="!open2"
              @change="changeRow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formalDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="formalDialogVisible = false"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="温馨提示"
      class="exam-type"
      :visible.sync="showReportSucc"
      width="400px"
    >
      <i class="el-icon-success success"></i> {{ examObj.name }} 执行完毕。
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showReportSucc = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <task ref="task"></task>
    <detail ref="detail"></detail>
  </div>
</template>

<script>
import { findByValue, encryption } from "@/core/util/util";
import {
  examTypeOptions,
  examSecurityLevelOptions,
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  examReportAutoSendOptions,
} from "@/core/util/constdata";

import {
  examUpdate,
  delObj,
  // addObj,
  delGostatistics,
  examswitch,
  createexamstat,
  createsubjectstat,
  regeneratesubjectstat,
  downloadclassnumteachreport,
  undosubjectstat,
  regenerateexamstat,
  questionswitch,
  putQuestionswitch,
  statOpenexam,
  checkexamquestioncombinstatus,
} from "@/core/api/exam/exam";
import { getclassandexamroom } from "@/core/api/school/schoolgradeclass";
import { STEP_MARKKNOWLEDGEPOINT } from "@/core/util/constdata";
import { getStore, setStore } from "@/core/util/store";
import { getSubjectList, fileDownloadByUrl } from "@/core/util/util";
import task from "./task.vue";
import detail from "./detail.vue";
export default {
  name: "ExamItem",
  filters: {
    getListSubject(value, level) {
      if (!value) return;
      let subjectOptions = getSubjectList(level);
      let subject = value.split(",").map(Number);
      let subjectItem = subjectOptions
        .map((item) => {
          if (subject.indexOf(item.subjectId) != -1) {
            return item.subjectName;
          } else {
            return false;
          }
        })
        .filter((item) => item);
      if (subject.indexOf(0) != -1) {
        subjectItem = ["总分"].concat(subjectItem);
      }
      return subjectItem.join(",");
    },
  },
  components: { task, detail },
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    reportStatusList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      progressType: true,

      open1: false,
      open2: false,
      delPassword: false,
      password: "",
      modelLoading: false,
      openClosedialogVisible: false,
      formalDialogVisible: false,
      dialogVisible: false,
      errStr: "",
      isRegenerate: 0,
      examObj: Object.assign({}, this.itemData),
      examTypeOptions: examTypeOptions,
      examSecurityLevelOptions: examSecurityLevelOptions,
      STEP_MARKKNOWLEDGEPOINT: STEP_MARKKNOWLEDGEPOINT,
      showTable: true,
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      examReportAutoSendOptions: examReportAutoSendOptions,
      subjectOptions: subjectOptions,
      levelOptions: levelOptions(),
      resultModelKey: 1,
      showResult: false,
      resultData: {},
      // 导出班级讲评报告 start
      downModel: false,
      // 当前科目
      indexPaperList: [],
      indexSubjectId: "",
      // 当前科目列表
      indexClassNumList: [],
      indexClassNum: [],
      checkedClassNumAll: false,
      checkedClassNumElse: false,
      checkedClassNumCancel: false,
      btnLoading: false,
      showReportSucc: false,
      // 导出班级讲评报告 end
      userInfo: {},

      detailDialogVisible: false,

      schoolIdList: [],
      questionList: [],
      indexPaper: {},
      reportObject: {},
      readonly: false,
    };
  },
  watch: {
    // 监听报告列表状态
    reportStatusList: {
      deep: true,
      handler() {
        // 如果有报告正在统计 是当前考试  保留数据  如果统计状态发生改变  就打开弹窗
        this.reportStatusList.map((item) => {
          if (this.examObj.id == item.id) {
            if (
              this.reportObject.statusStat != item.statusStat &&
              item.statusStat != 1 &&
              item.statusStat != 2 &&
              this.reportObject.id &&
              this.examObj.status != 1
            ) {
              this.showReportSucc = true;
            }
            let { statSubjectIdList, statType } = item;
            if (statType && statType == 2) {
              // 单科统计
              // 判断下  如果科目相同判断
              if (statSubjectIdList && statSubjectIdList.length > 0) {
                statSubjectIdList = statSubjectIdList.map((index) =>
                  Number(index)
                );
                if (!this.examObj.paperList) return;
                this.examObj.paperList.map((index) => {
                  if (statSubjectIdList.indexOf(index.subjectId) != -1) {
                    index.statStatus = item.statusStat;
                  }
                });
              }
            }
            this.examObj.status = item.statusExam;
            this.reportObject = Object.assign({}, item);
          }
        });
      },
    },
  },
  created() {
    this.setSwitch();
    this.userInfo = getStore({ name: "userInfo" });
    if (
      this.$route.query.examId &&
      this.$route.query.examId != this.itemData.id
    ) {
      this.showTable = false;
    }
  },
  methods: {
    showEdit() {
      this.$parent.editList(this.examObj);
    },
    initRate(paperRate) {
      // console.log(paperRate);
      this.progressType = false;
      this.examObj.paperList.map((index) => {
        paperRate.map((item) => {
          if (item.paperId == index.paperId) {
            // if (item.paperId == 40194) {
            //   console.log(item);
            // }
            Object.assign(index, item);
          }
        });
      });
    },
    async checkexamquestioncombinstatus(data) {
      const res = await checkexamquestioncombinstatus(data);
      this.errStr = res.data.data;
    },
    hanldClick() {
      this.readonly = false;
    },
    // 开启考试
    statOpenexam() {
      let data = {
        examId: this.examObj.id,
      };
      statOpenexam(data).then(() => {
        this.$message({
          message: "开启成功",
          type: "success",
          showClose: true,
        });
        this.examObj.status = 1;
        this.reportObject.statusStat = 2;
        this.reportObject.jobMessage = "开启考试中......";
        this.$emit("onloadGetList");
        this.setSwitch();
      });
    },
    // 修改选项s
    changeRow(item) {
      let data = {
        examPaperId: item.examPaperId,
        questionNum: item.examQuestionNumber,
        type: item.typeT ? "1" : "0",
      };
      this.putQuestionswitch(data, item);
    },
    putQuestionswitch(data, item) {
      putQuestionswitch(data)
        .then(() => {
          item.markSwitch = data.type;
          // this.$message({
          //   message: "设置成功！",
          //   type: "success",
          // });
          // this.formalDialogVisible = false;
          // this.btnLoading = false;
        })
        .catch(() => {
          this.questionList.map((item) => {
            if (item.markSwitch == 1) {
              item.typeT = true;
            } else {
              item.typeT = false;
            }
          });
        });
    },
    changeOpen() {
      let data = {
        examPaperId: this.indexPaper.paperId,
      };
      if (this.open2) {
        data.type = 1;
      } else {
        data.type = 0;
      }
      this.examswitch(data, 1);
    },
    async questionswitch() {
      let res = await questionswitch({
        examPaperId: this.indexPaper.paperId,
      });
      this.questionList = res.data.data;
      this.questionList.map((item) => {
        if (item.markSwitch == 1) {
          item.typeT = true;
        } else {
          item.typeT = false;
        }
      });
      this.questionList = JSON.parse(JSON.stringify(this.questionList));
      // console.log(this.questionList);
    },
    // 开启阅卷
    openPaper() {
      let data = {
        examPaperId: this.indexPaper.paperId,
      };
      if (this.open1) {
        data.type = 2;
      }
      if (this.open2) {
        data.type = 1;
      }
      if (!this.open2 && !this.open1) {
        data.type = 0;
      }
      if (data.type == 2 && this.indexPaper.formalMark) {
        this.$message({
          message: "试评只能开启一次！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.examswitch(data);
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          // done();
          this.cancel();
        })
        .catch(() => {});
    },
    cancel() {
      this.openClosedialogVisible = false;
      this.setSwitch();
      // this.indexPaper
    },
    showSwitch(item) {
      let data = {
        examPaperId: item.paperId,
        examId: item.examId,
        subjectId: item.subjectId,
      };
      this.$router.push({
        name: "unionMarkingTask",
        query: data,
      });
    },
    getTabShow(key, subjectId, row) {
      let type = false;
      let userInfo = getStore({ name: "userInfo" });

      if (
        this.examObj.examUnionWorkList &&
        this.examObj.examUnionWorkList.length > 0
      ) {
        this.examObj.examUnionWorkList.forEach((item) => {
          if (item.subjectType == key && item.subjectId == subjectId) {
            if (item.teacherIds.indexOf(userInfo.teacherId) != -1) {
              type = true;
            }
          }
        });
      }
      if (key == 3 && row.ifTaskAllocate == 1) {
        type = true;
      }
      if (key == 8 && row.ifProcessMonitor == 1) {
        type = true;
      }
      if (userInfo.roleId != 5) {
        type = true;
      }
      return type;
    },
    // 权限跳转 分组问题处理
    getLinkShow(key, subjectId) {
      let type = false;
      let userInfo = getStore({ name: "userInfo" });

      if (
        this.examObj.examUnionWorkList &&
        this.examObj.examUnionWorkList.length > 0
      ) {
        this.examObj.examUnionWorkList.forEach((item) => {
          if (item.subjectType == key && item.subjectId == subjectId) {
            if (item.teacherIds.indexOf(userInfo.teacherId) != -1) {
              type = true;
            }
          }
        });
      }

      return type;
    },
    // 判断列表菜单是否显示
    getListShow(key) {
      let type = false;
      let userInfo = getStore({ name: "userInfo" });
      // ifProcessMonitor
      if (
        this.examObj.examUnionWorkList &&
        this.examObj.examUnionWorkList.length > 0
      ) {
        this.examObj.examUnionWorkList.forEach((item) => {
          if (item.subjectType == key) {
            type = true;
          }
        });
      }
      if (this.examObj.paperList && this.examObj.paperList.length > 0) {
        this.examObj.paperList.forEach((item) => {
          if (item.ifTaskAllocate == 1 && key == 3) {
            type = true;
          }
        });
      }
      if (userInfo.roleId != 5) {
        type = true;
      }
      return type;
    },
    // 根据权限判断按钮是否显示
    getRoleShow(key) {
      // key 判断对应的值
      let type = false;
      let userInfo = getStore({ name: "userInfo" });

      if (
        this.examObj.examUnionWorkList &&
        this.examObj.examUnionWorkList.length > 0
      ) {
        this.examObj.examUnionWorkList.forEach((item) => {
          if (item.type == key) {
            type = true;
          }
        });
      }
      if (userInfo.roleId != 5) {
        type = true;
      }
      if (key == 5) {
        if (this.examObj.securityLevel == 2 && userInfo.roleId == 5) {
          type = false;
        }
      }
      if (
        this.examObj.securityLevel == 2 &&
        userInfo.roleId == 5 &&
        key == -1
      ) {
        type = false;
      }

      return type;
    },
    showBtn() {
      // console.log();
      let type = true;
      let userInfo = getStore({ name: "userInfo" });
      if (userInfo.roleId == 5) {
        type = false;
      }
      // if (this.examObj.status > 1) {
      //   type = false;
      // }
      return type;
    },
    linkToMonitor(item, examObj) {
      let data = {
        examPaperId: item.paperId,
        examId: item.examId,
        subjectId: item.subjectId,
        examType: examObj.examType,
        getType: item.getType,
        markType: item.markType,
        routerName: this.$route.name,
        // subjectName: item.subjectName,
      };
      setStore({
        name: "ifProcessMonitor",
        content: "",
      });
      // console.log(data);
      if (!item.statusSheet) {
        this.$message({
          showClose: true,
          message: "尚未设置答题卡",
          type: "warning",
        });
        return;
      }
      if (item.sheetMarkType) {
        this.$message({
          showClose: true,
          message: "先阅后扫不支持查看进度监控！",
          type: "warning",
        });
        return;
      }
      let userInfo = getStore({ name: "userInfo" });

      if (userInfo.roleId == 5) {
        if (
          !this.getLinkShow(8, item.subjectId, item) &&
          !this.getLinkShow(9, item.subjectId, item) &&
          item.ifProcessMonitor == 1
        ) {
          setStore({
            name: "ifProcessMonitor",
            content: item.ifProcessMonitor,
          });
          this.$router.push({
            name: "groupProgress",
            query: data,
          });
          return;
        }

        if (
          !this.getTabShow(8, item.subjectId, item) &&
          !this.getTabShow(9, item.subjectId, item)
        ) {
          this.$message({
            showClose: true,
            message: "当前账号无权限",
            type: "warning",
          });
          return;
        }
        let arrayM = this.examObj.examUnionWorkList.filter(
          (index) => index.subjectType == 8 && item.subjectId == index.subjectId
        );

        if (arrayM.length > 0 || item.ifProcessMonitor == 1) {
          this.$router.push({
            name: "unionMarkingProgress",
            query: data,
          });
        } else {
          this.$router.push({
            name: "unionGradeQuality",
            query: data,
          });
        }
      } else {
        if (item.markAbnormal) {
          this.$router.push({
            name: "unionMarkingTheAlarm",
            query: data,
          });
        } else {
          this.$router.push({
            name: "unionMarkingProgress",
            query: data,
          });
        }
      }
    },
    // 开始扫描
    scanrecordCheckRecognition(item, examObj) {
      let data = {
        examPaperId: item.paperId,
        examId: item.examId,
        level: examObj.level,
        examType: examObj.examType,
        // subjectName: item.subjectName,
        // examName: examObj.name,
        // grade: examObj.grade,
        // schoolName: examObj.schoolName,
      };
      this.$router.push({
        name: this.$route.name != "examlist" ? "unionscanSheet" : "scanSheet",
        query: data,
      });
    },
    // 阅卷分配
    correctTask(val, name) {
      // console.log(val);
      if (val.sheetMarkType == 1) {
        return;
      }
      val.examName = name;

      let data = {
        // examName: name,
        paperId: val.paperId,
        examId: val.examId,
        examType: this.examObj.examType,
      };

      if (val.markType == 2) {
        this.$router.push({
          name: "ExamClassReadCorrectTask",
          query: data,
        });
      } else {
        this.$router.push({
          name: "unionExamCorrectTask",
          query: data,
        });
      }
    },
    // 开启关闭  阅卷
    examSwitchChange(val, examObj) {
      // console.log(val);
      if (examObj.examType == 0) {
        this.switchCheck(val, examObj);
        return;
      }
      this.setSwitch();
      this.indexPaper = val;
      this.open1 = false;
      this.open2 = false;
      if (this.indexPaper.formalMark == 1) {
        //
        this.questionswitch();
        if (this.indexPaper.examSwitch == 1) {
          this.open2 = true;
        }
        this.formalDialogVisible = true;
      } else {
        this.openClosedialogVisible = true;
        if (this.indexPaper.examSwitch == 2) {
          this.open1 = true;
        }
      }
    },
    switchCheck(val) {
      let str = `是否${val.examSwitch ? "关闭" : "开启"}阅卷`;
      this.$confirm(str, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            examPaperId: val.paperId,
            type: val.examSwitch ? 0 : 1,
          };
          this.examswitch(data, 1);
        })
        .catch(() => {
          this.setSwitch();
        });
    },
    examswitch(data, type) {
      this.btnLoading = true;
      examswitch(data)
        .then(() => {
          this.$message({
            message: data.type == 0 ? "关闭成功" : "开启成功",
            type: "success",
            showClose: true,
          });

          this.openClosedialogVisible = false;
          this.btnLoading = false;
          this.examObj.paperList.map((item) => {
            if (item.paperId == data.examPaperId) {
              if (data.type == 1) {
                item.formalMark = 1;
              }
              item.examSwitch = data.type;
            }
          });

          this.setSwitch();
          if (type) {
            // this.questionswitch();
            this.questionList.map((item) => {
              item.markSwitch = data.type ? 1 : 0;
              item.typeT = data.type ? true : false;
            });
            this.questionList = JSON.parse(JSON.stringify(this.questionList));
          } else {
            this.indexPaper = {};
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setSwitch() {
      // console.log(this.examObj.paperList);
      if (!this.examObj.paperList || this.examObj.paperList.length == 0) {
        return;
      }
      this.examObj.paperList.map((item) => {
        if (item.examSwitch != 0) {
          item.examSwitchType = true;
        } else {
          item.examSwitchType = false;
        }
      });
      this.examObj = JSON.parse(JSON.stringify(this.examObj));
    },
    // 显示发布成绩项
    showResults(v) {
      // getLevel().then()
      this.resultModelKey = new Date().getTime();
      this.showResult = true;
      v.examId = v.id;
      this.resultData = v;
      // console.log(v);
    },
    showExamDetail() {
      this.$refs.detail.init();
    },
    // 删除考试
    deleteHandle() {
      let endExam = false;
      if (this.examObj.paperList) {
        this.examObj.paperList.map((item) => {
          if (item.examSwitch) {
            endExam = true;
          }
        });
      }

      if (endExam) {
        this.$message({
          showClose: true,
          message: `当前考试正处于阅卷状态，请关闭阅卷开关，再执行删除操作`,
          type: "error",
        });
        return;
      }
      this.delPassword = true;
      this.password = "";
    },
    monitorBack() {
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        id: this.examObj.id,
        password: user.password,
      };
      this.delObj(data);
    },
    delObj(data) {
      this.modelLoading = true;
      delObj(data)
        .then((res) => {
          this.modelLoading = false;
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "warning",
              showClose: true,
            });
          } else {
            // this.$message.success("删除成功");
            this.$message({
              message: "删除成功!",
              type: "success",
              showClose: true,
            });
            this.delPassword = false;
            let time = new Date().getTime();
            this.$emit("setList", time);
          }
        })
        .catch(() => {
          this.modelLoading = false;
        });
    },
    // 修改
    editList(v) {
      this.$emit("editList", v);
    },
    showTask() {
      this.$refs.task.init(this.examObj);
    },
    /**
     * 各个考试的功能按钮点击
     * index: 1-8(考生管理│模板设置│试题管理│临时榜│考务权限│成绩管理│微课录制│单科统计)
     * item: 考试信息
     */
    examFunctionBtns(index, item) {
      switch (index) {
        case 1: {
          // console.log(item);
          // console.log(index)
          this.examStudentHandle(item.id, item.name, item.level, item.status);
          break;
        }
        case 2: {
          this.examTemplateHandle(item.id, item.name, 0);
          break;
        }
        case 3: {
          this.examPaperHandle(item.id);
          break;
        }
        case 4: {
          //
          this.examRankingHandle(item.id, item.status);
          break;
        }
        case 5: {
          this.ExamDistributeHandle(item.id, item.level);
          //
          break;
        }
        case 6: {
          this.examResultHandle(item.id, item.name, item.status);
          break;
        }
        case 8: {
          // this.showDownload(item);
          this.$router.push({
            path: "/exam/ExamClassReport",
            query: {
              name: item.name,
              classNum: item.classNumm,
              examId: item.id,
              questionNum: item.questionNum,
              subjectId: item.subjectId,
            },
          });
          break;
        }
        case 9: {
          // 阅卷老师管理
          this.examTeacherManage(item.id, item.name, item.level);
          break;
        }
        case 10: {
          // 阅卷老师管理
          this.scanning(item.id, item.name, item.level);
          break;
        }
        default: {
          //
          break;
        }
      }
    },
    findLabelByValue(options, value) {
      return findByValue(options, value);
    },

    // 导出班级讲评报告
    showDownload(item) {
      this.indexPaperList = item.paperList;
      this.indexSubjectId = this.indexPaperList[0].subjectId;
      // let query = { level: item.level, year: item.year };
      // this.indexClassNum = [];

      getclassandexamroom(item.id).then((response) => {
        // this.indexClassNumList = .;
        this.indexClassNumList = [];
        response.data.data.classNums.map((index) => {
          this.indexClassNumList.push({
            classNum: index,
          });
        });
        this.downModel = true;
      });
    },
    // 全选反选取消
    changeClassNum(val) {
      // checkedClassNumAll checkedClassNumElse checkedClassNumCancel
      if (val == 1) {
        this.checkedClassNumElse = false;
        this.checkedClassNumCancel = false;
        this.indexClassNum = [];
        // if (this.checkedClassNumAll) {
        this.indexClassNumList.forEach((index) => {
          this.indexClassNum.push(index.classNum);
        });
        // }
      }
      if (val == 2) {
        this.checkedClassNumAll = false;
        this.checkedClassNumCancel = false;
        let array = [];
        this.indexClassNumList.forEach((index) => {
          // console.log(index);
          // console.log(this.indexClassNum);
          if (
            this.indexClassNum.filter((i) => i == index.classNum).length == 0
          ) {
            array.push(index.classNum);
          }
        });
        this.indexClassNum = array;
      }
      if (val == 3) {
        this.checkedClassNumElse = false;
        this.checkedClassNumAll = false;
        this.indexClassNum = [];
      }
    },
    delstatic(val) {
      // delGostatistics(id).then(() => {
      this.$confirm("是否确认清空统计", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delGostatistics(val.paperId).then(() => {
            // this.$message.success("清空统计成功");
            this.$message({
              message: "清空统计成功!",
              type: "success",
              showClose: true,
            });
            val.statusStatistic = 0;
          });
        })
        .catch(function () {
          // return ;
        });
      // });
    },
    generatestatic(val) {
      let actionStr = "生成统计";
      if (val.statId) {
        actionStr = "重新生成统计";
      }
      let str = "是否确定" + actionStr;
      let data = {
        subjectId: val.subjectId,
        examId: val.examId,
      };
      this.$confirm(str, "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: actionStr,
        cancelButtonText: "撤回统计",
        type: "warning",
      })
        .then(() => {
          // this.$message.success("统计中，请稍后！");
          // console.log(val);
          // console.log(val.statId);
          // console.log(val.statStatus);
          // if (val) return;
          if (!val.statId && !val.statStatus) {
            createsubjectstat(data).then(() => {
              // this.$message.success("统计中,请稍后!");
              this.$message({
                message: "统计中,请稍后!",
                type: "success",
                showClose: true,
              });
              val.statId = 1;
              val.statStatus = 2;
            });
          } else {
            regeneratesubjectstat(data).then(() => {
              // this.$message.success("统计中,请稍后!");
              this.$message({
                message: "统计中,请稍后!",
                type: "success",
                showClose: true,
              });
              val.statId = 1;
              val.statStatus = 2;
            });
          }
        })
        .catch((action) => {
          // console.log(action);
          if (action == "cancel") {
            undosubjectstat(data).then(() => {
              // this.$message.success("撤回统计成功");
              this.$message({
                message: "撤回统计成功!",
                type: "success",
                showClose: true,
              });
              val.statId = 0;
              val.statStatus = 0;
            });
          }
        });
    },
    async createGeneratestatic(val) {
      let str = "是否确定生成统计";
      if (val.statId) {
        str = "是否确定重新生成统计";
      }
      let data1 = {
        subjectId: val.subjectId,
        examId: val.examId,
      };
      try {
        await this.checkexamquestioncombinstatus(data1);
      } catch {
        this.errStr = "";
      }
      // console.log(val.sheetMarkType);
      if (val.examSwitch && val.sheetMarkType != 1) {
        this.$message({
          showClose: true,
          message: `请先关闭阅卷`,
          type: "error",
        });
        return;
      }
      let data = {
        subjectId: val.subjectId,
        examId: val.examId,
      };
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, str),
          h("p", { style: "color: red" }, this.errStr),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          // this.$message.success("统计中，请稍后！");
          // console.log(val);
          // console.log(val.statId);
          // console.log(val.statStatus);
          // if (val) return;
          if (!val.statId && !val.statStatus) {
            createsubjectstat(data).then(() => {
              // this.$message.success("统计中,请稍后!");
              // this.$message({
              //   message: "统计中,请稍后!",
              //   type: "success",
              //   showClose: true,
              // });
              this.$message({
                message: "统计已创建,请前往统计分析页面开启!",
                type: "success",
                showClose: true,
              });
              val.statId = 1;
              val.statStatus = 2;
            });
          } else {
            regeneratesubjectstat(data).then(() => {
              // this.$message.success("统计中,请稍后!");
              this.$message({
                message: "统计已创建,请前往统计分析页面开启!",
                type: "success",
                showClose: true,
              });
              val.statId = 1;
              val.statStatus = 2;
            });
          }
        })
        .catch(() => {
          // undosubjectstat(data).then(() => {
          //   this.$message.success("撤回统计成功");
          //   val.statId = 0;
          // });
        });
    },

    // 导出报告
    downloadclassnumteachreport() {
      if (this.indexClassNum.length == 0) {
        this.$message({
          message: "请选择班级！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      let data = {
        examId: this.examObj.id,
        subjectId: this.indexSubjectId,
        classNums: this.indexClassNum.join(","),
      };
      downloadclassnumteachreport(data)
        .then((res) => {
          // console.log(res);
          this.btnLoading = false;
          if (!res.data.data.url) {
            this.$message({
              message: "报告不存在",
              type: "warning",
              showClose: true,
            });
          } else {
            fileDownloadByUrl(res.data.data.url, res.data.data.name);
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    submitExam() {
      if (this.examObj.statId) {
        this.regenerateexamstat();
        //
      } else {
        this.createexamstat();
      }
    },
    regenerateexamstat() {
      let data = {
        examId: this.examObj.id,
        isRegenerate: this.isRegenerate,
      };
      this.btnLoading = true;
      regenerateexamstat(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$nextTick(() => {
            this.examObj.status = 2;
          });
          this.examObj.statId = 0;
          this.examObj.paperList.map((item) => {
            item.statId = 0;
            item.statStatus = 0;
          });
          this.$message({
            message: "考试已结束！统计生成中，请稍后！",
            type: "success",
            showClose: true,
          });
          // if (this.isRegenerate) {
          // this.examUpdate(2);
          // }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 结束考试
    async overExam() {
      let data = {
        examId: this.examObj.id,
      };
      try {
        await this.checkexamquestioncombinstatus(data);
      } catch {
        this.errStr = "";
      }

      // console.log(this.errStr);
      let endExam = false;
      this.examObj.paperList.map((item) => {
        if (item.examSwitch) {
          endExam = true;
        }
      });
      if (endExam) {
        this.$message({
          showClose: true,
          message: `请先关闭 '${this.examObj.name}' 所有科目阅卷开关`,
          type: "error",
        });
        return;
      }
      // if(examSwitch)
      this.dialogVisible = true;
    },
    createexamstat() {
      let data = {
        examId: this.examObj.id,
      };
      this.btnLoading = true;

      createexamstat(data)
        .then(() => {
          this.examObj.statId = 1;
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$nextTick(() => {
            this.examObj.status = 2;
          });
          this.examObj.statId = 0;
          this.examObj.paperList.map((item) => {
            item.statId = 0;
            item.statStatus = 0;
          });
          // statId
          this.$message({
            message: "考试已结束！统计生成中，请稍后！",
            type: "success",
            showClose: true,
          });
          this.$emit("onloadGetList");
          // this.examUpdate(2);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 开启考试
    startExam() {
      this.$confirm("是否重新开启考试！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.statOpenexam();
        })
        .catch(() => {
          //
        });
    },
    examUpdate(val) {
      examUpdate(this.examObj.id, val).then(() => {
        if (val == 2) {
          this.$message({
            message: "考试已结束！统计生成中，请稍后！",
            type: "success",
            showClose: true,
          });
        }
        if (val == 1) {
          this.$message({
            message: "考试开启成功",
            type: "success",
            showClose: true,
          });
        }
        this.$emit("onloadGetList");
        this.$nextTick(() => {
          this.examObj.status = val;
        });
      });
    },
    // 考生管理
    examStudentHandle(examId, examName, level, status) {
      this.$router.push({
        name:
          this.$route.name != "examlist"
            ? "unionExamNewStudent"
            : "ExamNewStudent",
        query: {
          examId: examId,

          levelId: level,
          status: status,
        },
      });
    },
    examTeacherManage(examId, examName, level) {
      this.$router.push({
        name:
          this.$route.name != "examlist"
            ? "unionExamNewTeacher"
            : "ExamNewTeacher",
        query: {
          examId: examId,
          levelId: level,
        },
      });
    },
    // 扫描点管理
    scanning(examId, examName, level) {
      this.$router.push({
        name: "ExamScanning",
        query: {
          examId: examId,
          levelId: level,
        },
      });
    },
    // 考务分工
    ExamDistributeHandle(examId, level) {
      this.$router.push({
        name:
          this.$route.name != "examlist"
            ? "unionExamDistribute"
            : "ExamDistribute",
        query: {
          examId: examId,
          levelId: level,
        },
      });
    },
    // 成绩管理
    examResultHandle(examId, examName, status) {
      this.$router.push({
        name:
          this.$route.name != "examlist"
            ? "unionExamNewResult"
            : "ExamNewResult",
        query: {
          examId: examId,
          examName: examName,
          status: status,
          routerName: this.$route.name,
        },
      });
    },
    // 模板设置
    examTemplateHandle(examId, examName, subjectId) {
      // console.log(subjectId)
      this.$router.push({
        name:
          this.$route.name != "examlist" ? "unionExamTemplate" : "ExamTemplate",
        query: {
          examId: examId,
          // examName: examName,
          subjectId: subjectId,
        },
      });
    },
    templateedit(exam) {
      // console.log(subjectId)
      this.$router.push({
        name:
          this.$route.name != "examlist" ? "uniontemplateedit" : "templateedit",
        query: {
          examId: exam.id,
          examName: exam.name,
          subjectId: 0,
        },
      });
    },
    // 临时榜
    // examRankingHandle() {
    examRankingHandle(examId, status) {
      this.$router.push({
        name:
          this.$route.name != "examlist" ? "unionExamRanking" : "ExamRanking",
        query: {
          examId: examId,
          status: status,
        },
      });
    },
    // 答案设置
    examPaperAnswerHandle(examId, paperId) {
      this.$router.push({
        name:
          this.$route.name != "examlist"
            ? "unionExamPaperAnswer"
            : "ExamPaperAnswer",
        query: {
          examId: examId,
          paperId: paperId,
        },
      });
    },
    // 前往统计参数页面
    goParameter() {
      this.$router.push({
        path: "/academic/statistics",
      });
    },
    // 试卷管理
    examPaperHandle(examId, subId) {
      this.$router.push({
        name: this.$route.name != "examlist" ? "unionexampaper" : "exampaper",
        query: {
          id: examId,
          subId: subId,
        },
      });
    },
    getSchoolName(id) {
      const data = this.schoolList.find((item) => item.id == id);
      return data.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamItem {
  .ant-input-affix-wrapper {
    width: auto;
  }
  .switch-icon {
    cursor: pointer;
  }
  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;

    .password-text {
      width: 0;
      height: 0;
      opacity: 0;

      ::v-deep .el-input__inner {
        width: 0;
        height: 0;
      }
    }

    ::v-deep .el-input {
      input {
        background-color: #ffffff;
        border-color: #dcdfe6;
      }
    }

    span {
      flex-shrink: 0;
    }
  }

  .stat-progress {
    padding-bottom: 24px;
  }

  .success {
    color: #0baa8e;
  }

  .err {
    color: red;
    font-weight: 500;
    font-size: 14px;
  }

  .exam-type {
    .table-style {
      margin-top: 24px;

      ::v-deep .el-table__body-wrapper {
        max-height: 200px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }

        ///*<!--修改 滑块 -->*/
        // border: 1px solid #DEE1E7;
        &::-webkit-scrollbar-thumb {
          background-color: #dee1e7;
        }
      }
    }

    .el-switch {
      margin-right: 40px;
    }

    .tips {
      background: #fff3f3;
      border: 1px dashed #d92020;
      padding: 10px 34px 0;
      line-height: 14px;
      margin-bottom: 43px;

      p {
        margin-bottom: 10px;

        &:first-child {
          position: relative;

          &::after {
            content: "!";
            position: absolute;
            letter-spacing: 0;
            width: 13px;
            height: 13px;
            text-align: center;
            line-height: 13px;
            border-radius: 50%;
            background-color: #f17e5e;
            left: -21px;
            font-size: 12px;
            top: 0.5;
            color: #ffffff;
          }
        }
      }
    }
  }

  margin-bottom: 24px;

  .export-parameter {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    // justify-content: flex-start;
    &:last-child {
      margin-bottom: 0;
      align-items: flex-start;
      // > span {
      //   // flex-shrink: 0;
      //   margin-bottom: 0;
      // }
    }

    > span {
      flex-shrink: 0;
      // margin-bottom: 0;
    }
  }

  .table-style {
    // .el-switch {
    //   ::v-deep .el-switch__core {
    //     border-color: #ebeef5 !important;
    //     background-color: #ebeef5 !important;
    //   }
    // }
    // .is-checked {
    //   ::v-deep .el-switch__core {
    //     border-color: rgb(11, 170, 142) !important;
    //     background-color: rgb(11, 170, 142) !important;
    //   }
    // }

    // opacity: 1;
    // transition: 0.2s;
    ::v-deep .el-progress-bar__inner {
      border-radius: 0;
    }

    ::v-deep .el-progress-bar__outer {
      height: 12px !important;
      border-radius: 0;
    }

    .el-icon-success {
      color: #0baa8e;
    }

    .el-icon-remove {
      color: rgba(0, 0, 0, 0.25);
    }

    ::v-deep .el-table__cell {
      padding: 11px 0;
    }

    .icon-img {
      width: 14px;
      // position: absolute;
      // left: 50%;
      // z-index: 50;
      // top: 6px;
      // transform: translateX(-50%);
      // -ms-transform: translateX(-50%); /* IE 9 */
      // -moz-transform: translateX(-50%); /* Firefox */
      // -webkit-transform: translateX(-50%); /* Safari 和 Chrome */
      // -o-transform: translateX(-50%); /* Opera */
    }

    // .scale-box {
    //   cursor: pointer;
    //   .hidden-box{

    //   }
    // }
    .kz-box {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .left {
        width: 100%;
        display: flex;
        .span-intro {
          flex-shrink: 0;
          margin-right: 5px;
        }
        .el-progress {
          width: 100%;
        }
      }

      .icon-img {
        flex-shrink: 0;
        margin-right: 11px;
      }
    }

    .table-box {
      position: relative;
      cursor: pointer;
      line-height: 24px;

      .left {
        height: 24px;

        ::v-deep .el-progress-bar__outer {
          margin-top: 6px;
        }
      }

      &:hover {
        .hidden-box {
          opacity: 1;
        }
      }

      .hidden-box {
        position: absolute;
        width: 96px;
        height: 24px;
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        /* IE 9 */
        -moz-transform: translateX(-50%);
        /* Firefox */
        -webkit-transform: translateX(-50%);
        /* Safari 和 Chrome */
        -o-transform: translateX(-50%);
        /* Opera */
        line-height: 22px;
        top: 0;
        color: rgba(15, 15, 15, 0.4);
        background: white;
        border-radius: 4px;
        border: 1px solid #d6d8db;
        opacity: 0;
        z-index: 10;
      }
    }
  }

  .hidden-list {
    display: none;
  }

  .hidden-table.show-table {
    // transform: rotate(180deg);
    // padding-top: 24px;

    i {
      transition: 0.3s;
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      /* IE 9 */
      -moz-transform: rotate(180deg);
      /* Firefox */
      -webkit-transform: rotate(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotate(180deg);
      /* Opera */
    }
  }

  .hidden-table {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    margin-left: 24px;
    border-top: none;

    span {
      line-height: 24px;
    }

    i {
      // width: 24px;
      margin-right: 8px;
      cursor: pointer;
      transition: 0.3s;
      //
    }
  }

  .boder-color {
    border-color: #d8d8d8;
  }

  .exception {
    ::v-deep .el-progress-bar__inner {
      background-color: #f56c6c !important;
    }

    ::v-deep .el-progress-bar__outer {
      background-color: #ffecec !important;
    }

    ::v-deep .el-progress__text {
      color: #f56c6c !important;
    }
  }

  ::v-deep .el-progress__text {
    // width: 90%;
    flex-shrink: 0;
    margin-top: 6px;
  }

  ::v-deep .el-progress-bar {
    padding-right: 10px !important;
  }

  ::v-deep .el-progress {
    display: flex;
    justify-content: space-between;
  }

  // 修改表格样式
  ::v-deep .table-style th.el-table__cell.is-leaf {
    border-bottom: 1px solid #d8d8d8;
  }

  ::v-deep .table-style td.el-table__cell {
    border-bottom: 1px solid #d8d8d8;
  }

  ::v-deep .table-style th.el-table__cell.is-leaf {
    border-bottom: 1px solid #d8d8d8;
  }

  ::v-deep .table-style td.el-table__cell {
    border-bottom: 1px solid #d8d8d8;
  }

  ::v-deep .el-table--border .el-table__cell {
    border-right: 1px solid #d8d8d8;
  }

  ::v-deep .el-table--group,
  .el-table--border {
    border: 1px solid #d8d8d8;
  }

  ::v-deep .table-style::before,
  .el-table--group::after,
  .el-table--border::after {
    background-color: #ffffff;
  }

  // .el-table {
  //   margin-top: 24px;
  // }
  .text-item-top {
    padding: 24px 24px 0;
    border: 1px solid #d8d8d8;
    border-bottom: 0;
    //
  }

  .show-item-bottom {
    border-bottom: 1px solid #d8d8d8;
  }

  p {
    margin-bottom: 0;
  }

  .btn-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    // padding: 0 24px;

    .btn-item {
      display: flex;
      color: #ddd;

      p {
        color: #606266;
        line-height: 14px;
        margin-right: 10px;
        margin-left: 5px;
        cursor: pointer;
        img {
          vertical-align: top;
          // line-height: 14px;
        }
        .on {
          display: none;
        }
        &:hover {
          color: $primary-color;
          img {
            display: none;
          }
          .on {
            display: inline-block;
          }
        }
      }

      p:nth-child(1) {
        margin-left: 0;
      }

      p:last-child {
        margin-right: 0;
      }

      .btn {
        color: #101011;
        margin-right: 40px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: #2474ed;
        }
      }
    }
  }

  .status.end {
    .tips {
      background-color: #9e9e9e;
    }
  }

  .status {
    display: flex;
    // margin: 17px 0;
    // padding: 0 24px;
    font-size: 12px;

    .tips {
      background-color: #ffab00;
      color: white;
      border-radius: 3px;
      margin-right: 10px;
      padding: 3px 8px;
    }

    p {
      padding: 3px 5px;
    }
  }

  .status-succ {
    .tips {
      background-color: $success-color;
    }
  }

  .status-ing {
    .tips {
      background-color: $primary-color;
    }
  }

  .top-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    // padding: 0 24px;
    .top-right-box {
      display: flex;
      align-items: center;
      color: #676b70;
    }

    .tips-item {
      display: flex;
      font-size: 12px;
      margin-right: 24px;
      margin-left: 8px;

      p {
        font-weight: 500;
        margin-right: 5px;
        color: #56575d;
        // background-color: #e6e6e4;
        background: #edf2ff;
        border-radius: 3px;
        padding: 2px 5px;
        line-height: 18px;
      }

      .securityLevel-err {
        background-color: red;
        color: #ffffff;
      }
    }

    .title {
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;

      .title-type {
        padding: 3px 6px;
        border-radius: 2px;
        font-size: 12px;
        margin-left: 8px;
        font-weight: 500;
        background-color: #edf2ff;
        color: #56575d;
      }
      .title-type-new {
        background-color: #ffefe3;
        color: #ff6600;
      }
    }
  }
}

.tags {
  margin: 0;

  .el-tag {
    margin-right: 24px;
    margin-bottom: 24px;
  }
}
</style>

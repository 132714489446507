<template>
  <div class="addSchool">
    <el-dialog
      title="添加学校"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="668px"
    >
      <div class="check-box">
        <div class="check-list-box">
          <div class="title">
            <span class="span-btn" @click="checkChange(1)">全选</span>
            <span class="span-btn" @click="checkChange(2)">反选</span>
          </div>
          <div class="check-cnt edit-scroll-style">
            <el-input
              v-model="keyword"
              placeholder="可输入学校名称搜索"
              @keyup.enter.native="handleClick"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="handleClick"
              >
              </i>
            </el-input>
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(v, i) in schoolList"
                :key="i"
                :label="v.schoolId"
                @change="setCheckList()"
              >
                {{ v.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="check-list-box check-list-box-r">
          <div class="title">
            <p>
              已选 <span>{{ checkAllList.length }}</span> 所学校
            </p>
            <el-button
              type="text"
              icon="el-icon-refresh-left"
              @click="clearItem()"
              >清空</el-button
            >
          </div>
          <div class="check-cnt edit-scroll-style">
            <el-input
              v-model="keywordR"
              placeholder="可输入学校名称搜索"
              @keyup.enter.native="setShowList()"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="setShowList()"
              >
              </i>
            </el-input>
            <div class="list-box">
              <div v-for="(v, i) in showList" :key="i" class="check-list">
                {{ v.name }}
                <i class="el-icon-close" @click="delItem(v)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AddSchool",
  components: {},
  data() {
    return {
      dialogVisible: false,
      keyword: "",
      keywordR: "",
      checkList: [],
      schoolList: [],
      showList: [],
      checkAllList: [],
    };
  },
  created() {},
  methods: {
    submit() {
      this.dialogVisible = false;
      let arr = this.checkList.concat(this.$parent.stuSchool);
      this.$parent.aubmitSchool(arr);
    },
    delItem(row) {
      this.checkList = this.checkList.filter((item) => item != row.schoolId);
      this.setCheckList();
    },
    clearItem() {
      this.checkList = [];
      this.setCheckList();
    },
    checkChange(type) {
      if (type == 1) {
        let arr = this.schoolList.map((item) => item.schoolId);
        this.checkList = this.checkList.concat(arr);
        this.checkList = [...new Set(this.checkList)];
      }
      if (type == 2) {
        let arr = this.schoolList.map((item) => item.schoolId);
        // 过滤出原本应该有的 但是又不在当前显示列表的
        let array = this.checkList.filter((item) => !arr.includes(item));
        arr = arr.filter((item) => !this.checkList.includes(item));
        this.checkList = array.concat(arr);
      }
      this.setCheckList();
    },
    // 根据本地数据设置所选择的内容
    setCheckList() {
      this.checkAllList = this.oldScohhoList.filter((item) =>
        this.checkList.includes(item.schoolId)
      );
      this.setShowList();
    },
    // 过滤出根据搜索条件拿到的数据
    setShowList() {
      this.showList = this.checkAllList.filter((item) => {
        if (
          !this.keywordR ||
          (this.keywordR && item.name && item.name.indexOf(this.keywordR) != -1)
        ) {
          return item;
        }
      });
    },
    init() {
      this.checkList = [];
      this.checkList = this.$parent.ruleForm.schoolIdList.filter(
        (item) => !this.$parent.stuSchool.includes(item)
      );
      this.dialogVisible = true;
      // console.log(this.$parent.stuSchool);
      this.oldScohhoList = this.$parent.schoolList.filter(
        (item) => !this.$parent.stuSchool.includes(item.schoolId)
      );
      this.schoolList = JSON.parse(JSON.stringify(this.oldScohhoList));
      this.setCheckList();
    },
    handleClick() {
      this.schoolList = this.oldScohhoList.filter((item) => {
        if (
          !this.keyword ||
          (this.keyword && item.name && item.name.indexOf(this.keyword) != -1)
        ) {
          return item;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.check-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .check-list-box-r {
    .title {
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        span {
          color: #2474ed;
        }
      }
      .el-button {
        color: rgb(245, 117, 116);
      }
    }
  }
  .check-list-box {
    width: 298px;
    border: 1px solid #dee0e7;
    .check-cnt {
      padding: 18px;
      height: 372px;
      .el-input {
        ::v-deep {
          .el-input__icon {
            line-height: 32px;
          }
          .el-input__inner {
            line-height: 32px;
            height: 32px;
          }
        }
      }
      .list-box {
        .check-list {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
          i {
            cursor: pointer;
          }
        }
      }
      .el-checkbox {
        display: block;
        margin-top: 18px;
        margin-bottom: 0;
      }
    }
    .title {
      padding: 10px 18px;
      background: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      .span-btn {
        margin-right: 18px;
        cursor: pointer;
      }
    }
  }
}
.addSchool {
}
</style>

<template>
  <div class="importFile">
    <el-dialog
      title="导入"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div class="tips">
            <div class="tips-title">【提示】</div>
            1、学校名称需要和提供到系统中的一致；
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <span class="down-tips" @click="downloadModel()"
                >参考学校模板.xls</span
              >
            </div>
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      btnType: 0,
    };
  },
  created() {},
  methods: {
    downloadModel() {
      let name = "联考-参考学校导入模板.xlsx";
      let url =
        "https://static.wtjy.com/template/%E8%81%94%E8%80%83-%E5%8F%82%E8%80%83%E5%AD%A6%E6%A0%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
      fileDownloadByUrl(url, name);
    },
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    upLoadSucc(data) {
      this.data = data;
      this.$emit("succFn", data);
      // console.log(data);
      // this.btnType = 1;
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        let url = "/exam/exam/import";
        let accept = ".xlsx,.xls";
        this.$refs.ElImportUpload.init(url, accept);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importFile {
}
</style>

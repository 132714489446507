<template>
  <div class="task">
    <el-dialog title="阅卷任务显示" :visible.sync="dialogVisible" width="490px">
      <div class="tips">
        <p><img src="@/assets/uExam/icon_waring.png" alt="" /> 注意：</p>
        1、关闭个人任务总量显示，阅卷教师将无法查看个人应批阅任务总数。<br />
        2、关闭整体任务总量显示，阅卷教师将无法查看本题整体（本组或本校）任务数量。
      </div>
      <el-table
        v-loading="listLoading"
        :data="paperList"
        border
        style="width: 100%"
      >
        <el-table-column label="科目" prop="subjectName" align="center">
        </el-table-column>
        <el-table-column label="个人任务量显示" align="center" width="150px">
          <template #header>
            <p>个人任务量显示</p>
            <el-switch
              v-model="taskOne"
              :active-value="1"
              :inactive-value="0"
              active-color="#2474ED"
              @change="changeValue('markCountPerson', 'taskOne')"
            >
            </el-switch>
          </template>
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.markCountPerson"
              active-color="#2474ED"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="总任务量显示"
          align="center"
          width="150px"
        >
          <template #header>
            <p>总任务量显示</p>
            <el-switch
              v-model="taskAll"
              active-color="#2474ED"
              :active-value="1"
              :inactive-value="0"
              @change="changeValue('markCountTotal', 'taskAll')"
            >
            </el-switch>
          </template>
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.markCountTotal"
              :active-value="1"
              :inactive-value="0"
              active-color="#2474ED"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { markcountTk, editMarkcountTk } from "@/core/api/exam/exam";
export default {
  name: "Task",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      listLoading: false,
      paperList: [],
      taskOne: 0,
      taskAll: 0,
    };
  },
  created() {},
  methods: {
    submit() {
      this.btnLoading = true;
      let data = {
        examId: this.examId,
        examMarkCountPerson: this.taskOne,
        examMarkCountTotal: this.taskAll,
        examMarkCountTkVOList: this.paperList,
      };
      editMarkcountTk(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    changeValue(key, key1) {
      this.paperList = this.paperList.map((item) => {
        item[key] = this[key1];
        return item;
      });
    },
    init(examObj) {
      this.dialogVisible = true;
      let data = {
        examId: examObj.id,
      };
      this.listLoading = true;
      markcountTk(data)
        .then((res) => {
          this.listLoading = false;
          this.examId = examObj.id;
          this.paperList = res.data.data.examMarkCountTkVOList;
          this.taskOne = res.data.data.examMarkCountPerson;
          this.taskAll = res.data.data.examMarkCountTotal;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.task {
  .tips {
    background: #fff3f3;
    border-radius: 4px;
    padding: 10px 42px;
    margin-bottom: 18px;
    p {
      margin-left: -19px;
      margin-bottom: 0;
    }
  }
}
</style>

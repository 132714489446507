<template>
  <div class="detail">
    <el-dialog
      title="查看考试信息"
      :visible.sync="detailDialogVisible"
      width="500px"
      class="info-box"
    >
      <el-form label-position="right">
        <el-form-item label="考试名称：">{{ indexExamInfo.name }}</el-form-item>
        <el-form-item label="考试日期：">
          {{ indexExamInfo.examDate }}
        </el-form-item>
        <el-form-item label="考试学段：">
          {{ indexExamInfo.level | setName(levelOptions) }}
        </el-form-item>
        <el-form-item label="入学年份：">
          {{ indexExamInfo.year }}
        </el-form-item>
        <!-- <el-form-item label="参考年级：">
          {{ indexExamInfo.grade }}
        </el-form-item> -->
        <el-form-item label="考试类型：">
          {{ findLabelByValue(examTypeOptions, examObj.type) }}
        </el-form-item>
        <el-form-item label="考试科目：">
          <span>{{ indexExamInfo.subjectNames }}</span>
        </el-form-item>
        <el-form-item v-if="indexExamInfo.ruleId > 0" label="新高考规则：">
          <span>{{ indexExamInfo.ruleId | setName(examnewruleListData) }}</span>
        </el-form-item>
        <el-form-item label="考生人数：">
          {{ indexExamInfo.examStudentCount }} 人
        </el-form-item>

        <el-form-item label="参考学校："
          >共 {{ schoolIdList.length }}所学校</el-form-item
        >
        <div class="tags edit-scroll-style">
          <div v-for="(item, index) in schoolIdList" :key="index">
            {{ item }}
          </div>
        </div>
        <el-form-item label="保密级别：">
          {{ indexExamInfo.securityLevel | setName(examSecurityLevelOptions) }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialogVisible = false">取 消</el-button>
        <el-button
          v-if="userInfo.roleId != 5"
          class="sub-btn"
          type="primary"
          @click="showFrom()"
          >修改考试</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { examinfoTk, schoollistbyexamid } from "@/core/api/exam/exam";
import { findByValue } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import {
  levelOptions,
  examSecurityLevelOptions,
  examTypeOptions,
} from "@/core/util/constdata";
export default {
  name: "Detail",
  components: {},
  filters: {
    getModelList(value, list) {
      if (!value) return;
      // let subjectOptions = getSubjectList(level);
      let subject = value.split(",").map(Number);
      let subjectItem = list
        .map((item) => {
          if (subject.indexOf(item.value) != -1) {
            return item.label;
          } else {
            return false;
          }
        })
        .filter((item) => item);
      return subjectItem.join(",");
    },
  },
  data() {
    return {
      examSecurityLevelOptions: examSecurityLevelOptions,
      examTypeOptions: examTypeOptions,
      indexExamInfo: {},
      detailDialogVisible: false,
      schoolIdList: [],
      examnewruleListData: [],
      levelOptions: levelOptions(),
      examObj: {},
      userInfo: {},
      modelList: [
        {
          label: "仅卷面分",
          value: 1,
        },
        {
          label: "卷面分及超越率",
          value: 2,
        },
        {
          label: "等级分",
          value: 3,
        },
        {
          label: "小题分",
          value: 4,
        },
        {
          label: "知识点分析",
          value: 5,
        },
        {
          label: "试卷夹",
          value: 6,
        },
        {
          label: "学科雷达图",
          value: 7,
        },
        {
          label: "答题卡",
          value: 8,
        },
      ],
    };
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
  },
  methods: {
    showFrom() {
      this.detailDialogVisible = false;
      this.$parent.showEdit();
    },
    findLabelByValue(options, value) {
      return findByValue(options, value);
    },
    async getSchoolList() {
      const res = await schoollistbyexamid({ examId: this.examObj.id });
      this.schoolIdList = res.data.data;
    },
    init() {
      this.examObj = this.$parent.examObj;
      let data = {
        examId: this.examObj.id,
      };
      examinfoTk(data).then((res) => {
        this.indexExamInfo = res.data.data;
        this.detailDialogVisible = true;
        this.schoolIdList = this.indexExamInfo.schools
          .split(";")
          .filter((item) => item);
      });
      this.examnewruleListData = this.$parent.$parent.examnewruleListData.map(
        (item) => {
          return {
            label: item.examModelDescribe,
            value: item.id,
          };
        }
      );
      //   console.log(this.examnewruleListData);
    },
  },
};
</script>
<style scoped lang="scss">
.detail {
  .info-box {
    .tags {
      display: flex;
      max-height: 200px;

      div {
        padding: 6px 10px;
        background: #f5f7fa;
        border-radius: 4px;
        margin-right: 8px;
        margin-bottom: 16px;
      }
    }
    ::v-deep .el-form-item__content {
      line-height: 21px;
      color: #101011;
    }

    ::v-deep .el-form-item__label {
      margin-bottom: 0;
      line-height: 21px;
      color: #606266;
      text-align: right;
    }

    ::v-deep .el-dialog__body .el-form-item {
      padding: 0;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

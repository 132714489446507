<template>
  <div class="ElImportUpload">
    <slot name="tips"></slot>
    <slot name="down"></slot>
    <el-upload
      ref="upload"
      :action="ImportURL"
      :headers="uploadHeaders"
      :on-success="handleSuccess"
      :before-upload="handleBefore"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :accept="accept"
      class="upload-demo"
      :file-list="fileList"
      :auto-upload="false"
      :multiple="true"
      drag
      :data="queryData"
      :limit="limit"
    >
      <div>
        <img src="@/assets/uExam/el_down.png" alt="" />
        <div class="el-upload__text">
          点击或将文件拖拽到这里上传 <br /><em>支持扩展名:{{ accept }}</em>
        </div>
      </div>

      <div slot="tip" class="el-upload__tip"></div>
    </el-upload>
  </div>
</template>

<script>
import { getStore } from "@/core/util/store";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ElImportUpload",
  components: {},
  props: {},
  data() {
    return {
      limit: 1,
      accept: "",
      queryData: {},
      fileList: [],
      ImportURL: "",
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
    };
  },
  created() {},
  methods: {
    submit() {
      if (this.$refs.upload.uploadFiles.length == 0) {
        this.$message({
          showClose: true,
          message: `请先上传文件！`,
          type: "error",
        });
        this.$emit("upLoadLoading");
        return;
      }
      this.$refs.upload.submit();
    },
    init(url, accept) {
      this.ImportURL = url;
      this.accept = accept;
      this.fileList = [];
    },
    handleExceed() {
      this.$message({
        showClose: true,
        message: `只能上传 ${this.limit} 个文件`,
        type: "error",
      });
    },
    // 上传成功
    handleSuccess(res) {
      let fileName = this.$refs.upload.uploadFiles[0].name;
      this.errorUrl = "";
      // console.log(res);
      if (res.code == 1) {
        this.message = res.msg || "文件内容有误。";
        this.$message({
          showClose: true,
          message: this.message,
          type: "error",
        });
        this.fileList = [];
      }
      if (res.code == 0) {
        /**
         * 上传文件/导入数据：<br />
         * status  是否成功 0-是  1-否<br />
         * url  OSS文件链接地址<br />
         * name  文件名<br />
         * msg  消息
         */
        let { status, name, msg, url, message } = res.data || {};
        if (status == 1 && url) {
          this.$confirm("文件导入失败，已为您下载失败原因。", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: false,
            type: "error",
          })
            .then(() => {})
            .catch(() => {});
          fileDownloadByUrl(url, name);
          this.fileList = [];
          this.$emit("upLoadLoading");
          return;
        }
        if (status == 1 && !url) {
          msg = msg || "文件内容有误。";
          this.$message({
            showClose: true,
            message: msg,
            type: "error",
          });
          this.fileList = [];
        }
        // 兼容下老导入
        if (undefined === status && message && message.indexOf("http") != -1) {
          this.$confirm("文件导入失败，已为您下载失败原因。", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: false,
            type: "error",
          })
            .then(() => {})
            .catch(() => {});
          fileDownloadByUrl(message);
          this.fileList = [];
          this.$emit("upLoadLoading");
          return;
        }
        if (undefined === status && message) {
          this.$message({
            showClose: true,
            message: message,
            type: "error",
          });
          this.fileList = [];
          return;
        }
        if (undefined === status && !message) {
          this.$confirm("‘" + fileName + "’导入成功！", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: false,
            type: "success",
          })
            .then(() => {})
            .catch(() => {});
          this.$emit("upLoadSucc", res);
        }
        if (status === 0) {
          this.$confirm("‘" + fileName + "’导入成功！", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: false,
            type: "success",
          })
            .then(() => {})
            .catch(() => {});
          this.$emit("upLoadSucc", res.data);
        }
      }
      this.$emit("upLoadLoading");
    },
    // 上传错误
    handleError() {
      this.$message({
        showClose: true,
        message: "文件上传失败，请根据模板修改后重试！",
        type: "error",
      });
      this.$emit("upLoadLoading");
    },
    // 文件上传
    handleBefore(file) {
      this.indexUpLoad = file;
      let size = 40 * 1024 * 1024;
      if (size < file.size) {
        this.$message({
          showClose: true,
          message: "上传失败：文件大小过大，请选择小于40MB的文件。",
          type: "warning",
        });
        this.$emit("upLoadLoading");
        return false;
      }
      let acceptList = this.accept.split(",");
      let type = true;
      acceptList.map((item) => {
        if (file.name.endsWith(item)) {
          type = false;
        }
      });
      if (type) {
        this.$message({
          showClose: true,
          message: `只能上传${this.accept}文件。`,
          type: "warning",
        });
        this.$emit("upLoadLoading");
        return false;
      }

      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.ElImportUpload {
  ::v-deep {
    .el-upload {
      width: 100%;
    }
    .down-box {
      .el-button {
        white-space: initial;
        text-align: left;
        line-height: 1.2;
      }
    }
    .el-upload-dragger {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 126px;
    }
  }
  .down-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .el-select {
      width: 164px;
    }
    .down-tips {
      color: #2474ed;
      border-bottom: 1px solid #2474ed;
      cursor: pointer;
    }
  }
  .tips {
    box-sizing: border-box;
    padding: 10px;
    color: #262829;
    font-size: 12px;
    margin-bottom: 18px;
    background-color: #f3f8ff;
    line-height: 2;

    .tips-title {
      color: #2474ed;
    }
  }
}
</style>
